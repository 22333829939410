export const navLinks = [
    {
        id: 0,
        label: "Druhy",
        to: "/druhy",
    },
    {
        id: 1,
        label: "Poddruhy",
        to: "/poddruhy",
    },
];
